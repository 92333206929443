const Header = () => {
    return (
        <header className="pt-12 flex justify-start items-center">
        <img src="/images/newpic.png" alt="personal pic" className="rounded-full h-24 w-24 left"  />
        <div className="text-2xl ml-4 font-black font-rubik ">Saleh Bubshait</div>
        </header>
    );
}

export default Header;
