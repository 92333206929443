import Header from '../Components/Header.js';
import Navbar from '../Components/Navbar.js';
import PageTitle from '../Components/PageTitle.js';
import PageP from '../Components/PageP.js';
import { Link } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Navbar></Navbar>
      <PageTitle title="Not found 😕" />
      <PageP>Oops! The page you're looking for seems to be lost in the vast digital universe. 🌌</PageP>
      <PageP>Please double-check the URL or navigate back to <Link to="/" className='text-indigo-600'>the homepage. 🏠 </Link></PageP>
      <div className='pb-6'></div>
    </div>
  );
}

export default App;