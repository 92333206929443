import Tag from "./Tag";
import { Link } from 'react-router-dom';

// image for each technology
const techImage = {
    "NodeJS": "/images/NodeJS2.svg",
    "Deno": "/images/deno.svg",
    "React": "/images/React.png",
    "Python": "/images/Python.png",
    "Java": "/images/Java.png",
    "JavaScript": "/images/JavaScript.png",
    "SQL": "/images/SQL.png",
    "Firebase": "/images/Firebase.png",
    "OpenCV": "/images/OpenCV.png",
    "p5.js": "/images/p5js.png",
    "AWS": "/images/AWS.png",
    "Tailwind": "/images/Tailwind.png",
    "Flutter": "/images/flutter.png",
    "Kotlin": "/images/Kotlin.png",
    "TypeScript": "/images/typescript.png",
    "React Native": "/images/react_native.png",
}

function ProjectItem({ title, description, link, tags, technologies, pageLink }) {
    return ( <div className="py-8">
        { pageLink && <Link to={pageLink} className="text-2xl font-bold text-left text-indigo-600 cursor-pointer"> {title} </Link>}
        {link ? 
        <a href={link} className="text-2xl font-bold text-left text-indigo-600 cursor-pointer" target="_blank"> {title} </a>
        : (!pageLink ? <div className="text-2xl font-bold text-left"> {title} </div> : null)}
        <div className="text-sm text-left text-gray-600 py-2"> {tags.map((tag) => (
            <Tag>{tag}</Tag>
        ))} </div>
        
        <div className="text-lg text-left text-gray-600"> {description} </div>
        <div className="text-lg text-left text-gray-600 py-2 flex">{technologies.map((tech) => (
            <Technology tech={tech} />
        ))} </div>

    </div> );
}

function Technology({tech}) {
    return (
        <div className="text-sm text-left text-gray-600 ml-3">
            <img src={techImage[tech]} alt={tech} className="w-10 h-10 rounded-full inline-block mr-2" />
        </div>
    )
}
export default ProjectItem;