import Header from '../Components/Header.js';
import Navbar from '../Components/Navbar.js';
import PageTitle from '../Components/PageTitle.js';
import PageP from '../Components/PageP.js';
import Typewriter from 'typewriter-effect';
import { useState } from 'react';



function App() {
  const [skipButtonVisible, setSkipButtonVisible] = useState(true);
  
  return (
    <div className="App">
      <Header></Header>
      <Navbar page="/"></Navbar>
      <PageTitle title="Home" />
      <PageP> {skipButtonVisible ? typing(skipButtonVisible, setSkipButtonVisible) : regularText()} </PageP>
      <div className="flex justify-center align-middle py-6">{skipButton(skipButtonVisible, setSkipButtonVisible)} </div> 
    </div>
  );
}

function typing(skipButtonVisible, setSkipButtonVisible) {
  return (
    <Typewriter
  options={{
    delay: 20
  }}
      onInit={(typewriter) => {
        typewriter.pauseFor(250)
          .typeString("Hey there, digital explorer! I'm Saleh Bubshait 🙋‍♂️.<br>")
          .pauseFor(500)
          .typeString(" You've landed on the launchpad of my ongoing journey in the expansive universe of Computer Science. Currently, I'm a Computing student at Imperial College London, immersing myself in this fascinating field with immense enthusiasm! 🌌<br><br>")
          .pauseFor(500)
          .typeString("As a software engineer with a strong focus in web development, I possess a rich blend of knowledge and experience. My journey in tech has been marked by a deep-seated passion for creating and innovating. Whether it's front-end or back-end, I find immense satisfaction in crafting engaging and efficient web solutions. 💻💙<br><br>")
          .pauseFor(500)
          .typeString("But wait, there's more! Beyond the world of web development, I've been exploring realms like App Development, AI, and Cloud Computing. It's like stepping into a sci-fi movie, but way cooler because it's real, and I get to create! 🤖💡<br><br>")
          .pause(500)
          .typeString("Want a sneak peek into what I've been up to? Check out my <a className='text-indigo-600' href='/projects'>Projects page 📂!</a><br><br>")
          .pauseFor(500)
          .typeString("And don't forget to stop by my <a className='text-indigo-600' href='/cv'>CV page 📄</a>! 🔐")
          .pauseFor(500)
          .typeString("<br><br>Thanks for stopping by! I hope you enjoy your stay. 🚀")
          .callFunction( () => setSkipButtonVisible(false) )
          .start();
  }}
/>
  )
}

function regularText() {
  return (
    <div>
      Hey there, digital explorer! I'm Saleh Bubshait 🙋‍♂️.<br></br>
      You've landed on the launchpad of my ongoing journey in the expansive universe of Computer Science. Currently, I'm a Computing student at Imperial College London, immersing myself in this fascinating field with immense enthusiasm! 🌌<br></br><br></br>
      As a software engineer with a strong focus in web development, I possess a rich blend of knowledge and experience. My journey in tech has been marked by a deep-seated passion for creating and innovating. Whether it's front-end or back-end, I find immense satisfaction in crafting engaging and efficient web solutions. 💻💙<br></br><br></br>
      But wait, there's more! Beyond the world of web development, I've been exploring realms like App Development, AI, and Cloud Computing. It's like stepping into a sci-fi movie, but way cooler because it's real, and I get to create! 🤖💡<br></br><br></br>
      Want a sneak peek into what I've been up to? Check out my <a className="text-indigo-600" href="/projects">Projects page 📂!</a><br></br><br></br>
      And don't forget to stop by my <a className="text-indigo-600" href="/cv">CV page 📄</a>! 🔐<br></br><br></br>
      Thanks for stopping by! I hope you enjoy your stay. 🚀
    </div>
  )
}



function skipButton(skipButtonVisible, setSkipButtonVisible) {
    return (
      <button className={`border-indigo-600 border-dashed hover:bg-indigo-700 hover:text-white border-2 text-indigo-600 font-bold py-2 px-4 rounded-full ${skipButtonVisible ? '' : "hidden"}`} onClick={() => setSkipButtonVisible(false)}>
        Skip
      </button>
    )
}

export default App;