import { Link } from 'react-router-dom';

const Navbar = ({page}) => {
    return (
        <div className="flex justify-between items-center h-16 bg-white text-black relative font-mono pt-16 font-semibold text-lg font-rubik text-gray-400" role="navigation">
                <Link className={page === "/" ? "text-black" : ""} to="/">HOME</Link>
                <Link className={page === "/CV" ? "text-black" : ""} to="/CV">CV</Link>
                <Link className={page === "/projects" ? "text-black" : ""} to="/projects">PROJECTS</Link>
                <Link className={page === "/contact" ? "text-black" : ""} to="/contact">CONTACT</Link>
        </div>

    )
}

export default Navbar;