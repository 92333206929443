import Header from '../Components/Header.js';
import Navbar from '../Components/Navbar.js';
import PageTitle from '../Components/PageTitle.js';
import PageP from '../Components/PageP.js';
import { Fade } from 'react-awesome-reveal';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

function App() {
  return (
    <div className="App">
      <Header></Header>
      <Navbar page="/CV"></Navbar>
      <PageTitle title="Curriculum Vitae (CV)" />
      <Fade>
        <PageP>Hello There! 👋🏻</PageP>
      </Fade>
      <Fade delay={700}>
        <PageP>Thanks for visiting my CV page. I'm currently in the process of building this section, so stay tuned for some exciting updates! 🚧 </PageP>
      </Fade>
      <Fade delay={2200}>
        <PageP>In the meantime, if you'd like to learn more about my qualifications and experiences, feel free to check out my PDF CV file, which can be accessed it <a href="/cv.pdf" className='text-indigo-600'>here. 📄 </a></PageP>
      </Fade>
      <Fade delay={4000}>
        <PageP>
      If you have any specific inquiries or would like to get in touch, please don't hesitate to reach out to me through the <a href="/contact" className='text-indigo-600'>Contact page </a> 🥰!
      </PageP>
      </Fade>
      <div className='pb-6'></div>
      
    </div>
  );
}

export default App;