import Header from '../Components/Header.js';
import Navbar from '../Components/Navbar.js';
import PageTitle from '../Components/PageTitle.js';
import PageP from '../Components/PageP.js';
import { Fade } from 'react-awesome-reveal';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { useForm, Controller } from "react-hook-form";
import { useState, useRef, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ClipLoader from "react-spinners/ClipLoader";


const schema = yup.object().shape({
  name: yup.string().required().min(2).max(50),
  email: yup.string().required().email(),
  message: yup.string().required().min(10)
});

function App() {
  const recaptchaRef = useRef();
  const { register, handleSubmit, formState:{ errors }, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: localStorage.getItem('name') || '',
      email: localStorage.getItem('email') || '',
    }
  });  
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [sent, setSent] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialRender(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []); 


  const submitForm = data => {
    console.log(data.recaptcha)
    if (!data.recaptcha) {
      setRecaptchaError(true);
      return;
    } else {
      setRecaptchaError(false);
    }

    localStorage.setItem('name', data.name);
    localStorage.setItem('email', data.email);

    setLoading(true);
    axios.post('https://api.bubshait.me/sendMessage', data, {timeout: 30000})
      .then(response => {
        console.log(response)
        setSent(true);
        setLoading(false);
        setSuccess(true);
      })
      .catch(error => {
        console.log(error)
        setSent(true);
        setLoading(false);
        setSuccess(false);
      });
  };


  return (
    <div className="App">
      <Header></Header>
      <Navbar page="/contact"></Navbar>
      <PageTitle title="Contact" />
      <Fade>
      <PageP>Hello again, Tech Adventurer! 🚀</PageP>
      </Fade>
      <Fade delay={isInitialRender ? 800 : 0}>
      <PageP>Whether you've got burning questions, exciting ideas, or just fancy a chat, I'm all ears! Use the form below to reach out and I'll get back to you in a flash.</PageP>
      </Fade>
      {!sent && <Fade delay={isInitialRender ? 2000 : 0}>
        <div className='pt-8 pb-6'>
          <form onSubmit={handleSubmit(submitForm)} className="w-full">
          <div className="mb-4">
            <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="name">
              Name
            </label>
            <input { ...register('name')} className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Name" />
            {errors.name && <ErrorMessage msg="Name is required and must be 2-50 characters long." /> }
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="username">
              Email
            </label>
            <input { ...register('email') } className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="email" type="text" placeholder="Email" />
            {errors.email && <ErrorMessage msg="Email is required and must be valid." /> }
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="username">
              Message
            </label>
            <textarea { ...register('message') } className="shadow appearance-none border rounded w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="10" id="message" type="text" placeholder="Message" />
            {errors.message && <ErrorMessage msg="Message is required and must be at least 10 characters long." /> }
          </div>
          <div className='py-4'>
              <Controller 
      name="recaptcha"
      control={control}
      defaultValue={null}
      rules={{ required: true }}
      render={({ field }) => (
        <ReCAPTCHA 
          ref={recaptchaRef}
          sitekey="6LeR4h0nAAAAAF8vjeWBUco2-p1NU44uDNUpbfa3" 
          onChange={value => field.onChange(value)}
          onExpired={() => field.onChange(null)}
        />
      )}
    />
    { recaptchaError && <ErrorMessage msg="Recaptcha is required" /> }
    </div>
          {!loading && <div className="flex items-center justify-between">
            <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
              Send 😊
            </button>
          </div>
}
          {loading && <div className="flex items-center justify-between">
            <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center" type="button">
            <ClipLoader size={30} color={'#ffffff'} loading={true} className='mr-2' /> Sending
            </button>
          </div>
            }

            
            </form>            
        </div>
      </Fade>
}
  
  {sent && <Fade delay={isInitialRender ? 2000 : 0}>
        <div className='pt-8 pb-6'>
          <PageP>
          {success ? <div className='text-indigo-600'>
          Thanks for reaching out! I'll get back to you as soon as possible. 📨
          </div> : <div className='text-red-500'>
          Oops! Something went wrong while trying to send your message 😔. Please send me an email instead at <a href="mailto:contact@bubshait.me" className="text-indigo-600 inline" target="_blank" rel="noreferrer"> Contact@Bubshait.me </a>
          </div>
}
          </PageP>
          {success && 
          <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6" type="button">
              {success ? "Send Another Message 😊" : "Try Again 😊"}
            </button>
}
        </div>
      </Fade>
}

      <Fade>
        <div className='pt-6'>
          <PageP>
          Alternatively, feel free to shoot me an email at <a href="mailto:contact@bubshait.me" className="text-indigo-600" target="_blank" rel="noreferrer">Contact@Bubshait.me</a>
          </PageP>
        </div>
      </Fade>
      <Fade>
        <div className='pt-3 pb-10'>
          <PageP>
          Looking to connect elsewhere? Find me on <a href="https://www.linkedin.com/in/sBubshait/" className='text-indigo-600' target="_blank" rel="noreferrer">LinkedIn</a> and <a href="https://github.com/sBubshait" className='text-indigo-600' target="_blank" rel="noreferrer">GitHub</a>. Let's make the digital world a smaller place together! 🌐🤝
          </PageP>
        </div>
      </Fade>
    </div>
  );
}

function ErrorMessage({msg}) {
  return (
    <PageP>
    <div className="text-red-500 italic">
        {msg}
    </div>
    </PageP>

  )
}

export default App;