import Header from '../Components/Header.js';
import Navbar from '../Components/Navbar.js';
import PageTitle from '../Components/PageTitle.js';
import PageP from '../Components/PageP.js';
import Typewriter from 'typewriter-effect';
import Tag from '../Components/Tag.js';
import { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import ProjectItem from '../Components/ProjectsItem.js';


const myProjects = [
  {
    title: "AsmaJS 🌟",
    description: "AsmaJS is an Arabic-oriented compiled programming language that seamlessly translates into JavaScript. Aimed at fostering linguistic diversity in tech, it makes coding more accessible for Arabic speakers.",
    tags: ["Programming Langugage"],
    link: "https://github.com/sBubshait/AsmaJS",
    technologies: ["NodeJS", "Deno" ]
  },
  {
    title: "ModuMarker 📝",
    description: "A versatile and customisable answer sheet processing tool using coordinate mapping for efficient analysis and evaluation.",
    tags: ["Other"],
    link: "https://github.com/sBubshait/ModuMarker",
    technologies: ["Python", "OpenCV"]
  },
  {
    title: "Web Search Engine 📖",
    description: "A web search engine that crawls the web, indexes pages, and provides a query search engine through simple CLI. It is built using Kotlin",
    tags: ["CLI Tools"],
    link: "https://github.com/sBubshait/searchEngine",
    technologies: ["Kotlin"]
  },
  {
    title: "MIPS Assembler 🤖",
    description: "A MIPS assembler that translates MIPS assembly code into machine code. It is built using Kotlin",
    tags: ["Other"],
    link: "https://github.com/sBubshait/MIPS-assembler",
    technologies: ["Kotlin"]
  },
  {
    title: "Craft Git Ignore 📂",
    description: "Craft-gitignore is your companion for effortlessly generating .gitignore files tailored to your project's needs. Craft-gitignore analyzes your code directory and creates a comprehensive .gitignore file with rules specific to the programming languages, operating systems, and common file types in your project. 🚀",
    tags: ["CLI Tools"],
    link: "https://github.com/sBubshait/craft-gitignore",
    technologies: ["TypeScript"]
  },
  {
    title: "Filesyncer 🔄",
    description: "A CLI tool for real-time synchronization of a local directory with an AWS S3 bucket. Using Node.js, it runs as a background service, automatically keeping your files up-to-date on AWS.",
    tags: ["CLI Tools"],
    link: "https://github.com/sBubshait/filesyncer",
    technologies: ["NodeJS", "AWS"]
  },
  {
    title: "AniMatch 🧲",
    description: "AniMatch is a web app that uses machine learning to match you with your next favorite anime. It uses content-based and collaborative-filtering recommendation system to recommend anime based on your input. It contains a database of over 30,000 animes and 100,000 users from all major anime listing platforms. It utilises the Matrix Factorization algorithm to generate recommendations after the inital content-based filtering.",
    tags: ["AI", "App Development"],
    link: "https://github.com/sBubshait/anime-recommender",
    technologies: ["React Native", "Python", "AWS"]
  },
  {
    title: "Desmos Drawer 🎨",
    description: "An app that will convert your favourite logo and images to a list of equations that can be drawn on Desmos! It is built using Python and OpenCV.",
    tags: ["CLI Tools"],
    link: "https://github.com/sBubshait/desmos-drawer",
    technologies: ["Python", "OpenCV"]
  },
  {
    title: "AI Connect 4 🕹️",
    description: "A Connect 4 game that uses the minimax algorithm to play against the user. The game is built using Python and the minimax algorithm is implemented using recursion.",
    tags: ["AI", "MiniMax"],
    link: "https://github.com/sBubshait/AIConnect4",
    technologies: ["Python", "p5.js"]
  },
  {
    title: "AI Tic Tac Toe 🤖",
    description: "A Tic Tac Toe game that uses the minimax algorithm to play against the user. The game is built using Python and the minimax algorithm is implemented using recursion.",
    tags: ["AI", "MiniMax"],
    link: "https://github.com/sBubshait/AITicTacToe",
    technologies: ["Python", "p5.js"]
  },
  {
    title: "PI Digits Calculator 🥧",
    description: "A program to calculate and check digits of PI relatively fast using Gauss-Legendre algorithm and arbitary precision. Used to calculate 1 million digits of PI!",
    tags: ["Other"],
    link: "https://github.com/sBubshait/CalcPI",
    technologies: ["Python"]
  },
  {
    title: "Eazy Reply 📧",
    description: "Email Assistant designed to automate your email responses and actions. EazyReply makes your email handling easy, efficient, and highly customizable.",
    tags: ["Automation"],
    link: "https://github.com/sBubshait/EazyReply",
    technologies: ["NodeJS"]
  },
  {
    title: "This Website 🌐",
    description: "This website is built using React and TailwindCSS! ",
    tags: ["Web Development"],
    link: "/",
    technologies: ["React", "Tailwind"]
  }



]

// delay 3000 then 3200

function App() {
  const [isTyping, setTyping] = useState(true);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const handleInitialRender = () => {
    setTimeout(() => {
      setIsInitialRender(false);
    }, 1100)
  }

  return (
    <div className="App">
      <Header></Header>
      <Navbar page="/projects"></Navbar>
      <PageTitle title="Projects" />
      <PageP>{isTyping ? typing(setTyping, handleInitialRender) : regularText()}</PageP>
      {/* Tags. Disabled for now */}
      {/* <Fade delay={0}>
          <div className='pt-6'>
            <Tag>Web Devlopment</Tag>
            <Tag>AI</Tag>
            <Tag>App Development</Tag>
          </div>
      </Fade> */}
      
          {myProjects.map((project) => (
            <Fade delay={isInitialRender ? 2200 : 0}>
              <ProjectItem title={project.title} description={project.description} tags={project.tags} link={project.link} technologies={project.technologies} />
            </Fade>
          ))}
      
    </div>
  );
}


function typing(setTyping, handleInitialRender) {
  return (
    <Typewriter
  options={{
    delay: 15
  }}
      onInit={(typewriter) => {
        typewriter.pauseFor(250)
          .typeString("Hey there! Here are some of the projects I have worked on 😁:<br>")
          .start()
          .callFunction(() => {
            setTyping(false);
            handleInitialRender();
          })
          }}
/>
  )
}



function regularText() {
  return (
    <div>
      Hey there! Here are some of the projects I have worked on 😁:<br></br>
    </div>
  )
}

export default App;