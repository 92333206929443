import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import React from 'react';
import Home from './Pages/Home';
import CV from './Pages/CV';
import Contact from './Pages/Contact';
import Projects from './Pages/Projects';
import NotFound from './Pages/NotFound';

const shortcuts = {
  '/article': 'https://www.uclsciencemagazine.com/article-t5/',
};

function Redirector({ to }) {
  const navigate = useNavigate();
  
  React.useEffect(() => {
    if (to) {
      window.location.href = to;
    } else {
      navigate('/'); // default to home
    }
  }, [to, navigate]);

  return null; 
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/CV' element={<CV />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/contact' element={<Contact />} />
        {Object.entries(shortcuts).map(([path, url]) => (
          <Route key={path} path={path} element={<Redirector to={url} />} />
        ))}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
